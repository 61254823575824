/************ Custom Font ************/
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Bold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Medium.ttf');
	font-weight: 500;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Book.ttf');
	font-weight: 400;
}
@font-face {
	font-family: Shojumaru;
	src: url('./assets/font/Bangers-Regular.ttf');
	font-weight: 400;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* border: solid fuchsia 1px; */
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

p.success {
	color: green;
}

p.danger {
	color: red;
}
/************ Navbar Css ************/
nav.navbar {
  padding: 0px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
  transition:  0.32s ease-in-out;
  background-color: #658147;
}
@media (max-width: 1025px) {
  nav.navbar {
    background-color: #658147;
  }
}
nav.navbar.scrolled {
  background-color: #658147;
}
nav.navbar a.navbar-brand {
    padding: 0;
    width: 100px;
    margin: 15px 0;
    margin-left: -1.2%;
}
@media (max-width: 450px) {
  nav.navbar a.navbar-brand {
    width: 50px;
    margin: 10px;
  }
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 20px;
  opacity: 0.75;
}
/* Navbar Highlighting */
nav.navbar .navbar-nav .navbar-link.active,
nav.navbar .navbar-nav .navbar-link:hover{
  opacity: 1;
}
span.navbar-text {
    display: flex;
    align-items: center;
}
.social-icon {
    display: inline-block;
    margin-left: 14px;
    margin-right: 14px;
}
.social-icon a {
    width: 42px;
    height: 42px;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a::before {
    content: "";
    width: 42px;
    height: 42px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    transform: scale(0);
	transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
    transform: scale(1);
}
.social-icon a img {
	width: 40%;
	z-index: 1;
	transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #121212;
}
.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #fff;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}
/* NEW STUFF BELOW */
/* For Navbar dropdowns? */
.navbar-nav .dropdown-menu a {
  color: black;
  font-family: Centra;
	src: url('./assets/font/CentraNo2-Medium.ttf');
	font-weight: 400;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 15px;
  opacity: 0.8;
}


/* For Camping? */
.navbar-nav .nav-link {
  padding: 0 25px;
  opacity: 0.8;
}
/* Navbar Highlighting */
.navbar-nav .nav-link.active,
.navbar-nav .nav-link:hover{
  opacity: 1;
  color: white;
}
/* Make Camping White after Dropdown */
#basic-nav-dropdown{
  color: white;
}
.navbar-nav .nav-link a{
  color: white;
  font-family: Centra;
	src: url('./assets/font/CentraNo2-Medium.ttf');
	font-weight: 400;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 20px;
  text-decoration: none;
}
/* Background Color of Navbar Dropdown Items */
.navbar .dropdown-menu .dropdown-item {
  background-color: white;
  color: black;
}
.navbar-nav .dropdown-menu a:hover{
  background-color: #658147;
  color: white;
}
/************ Home Scroll Container ************/
#scrollContainer {
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  height: 100vh; /* Ensure it takes the full viewport height */
}
.snapScrolling {
  scroll-snap-align: start;
}
/************ Banner Css ************/
.banner {
  padding-top: 100px;
  background-color: #658147;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 650px) and (max-width: 1050px) {
  .banner {
    padding-top: 220px;
  }
}
@media (max-width: 650px) {
  .banner {
    padding-top: 0px;
    padding-left: 25px;
  }
}
.banner h1 {
  font-size: 90px;
  font-weight: 700;
  letter-spacing: 1.5px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
  background: linear-gradient(to right, rgb(255, 255, 255) 0%, rgba(69, 92, 55, 1) 44%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: left;
  padding-bottom: 20px;
  word-wrap: break-word;
}
@media (max-width: 900px) {
  .banner h1 {
    margin-top: 50px;
    font-size: 48px;
    padding-bottom: 0px;
  }
}
@media (max-width: 450px) {
  .banner h1 {
    font-size: 40px;
    margin-top: 65px;
  }
}
.banner .f-let::first-letter{
  font-size: 90px;
}
@media (max-width: 1050px) {
  .banner .f-let::first-letter {
    font-size: 90px;
  }
}
.banner h2 {
  padding-top: 50px;
  opacity: 0.60;
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 1.5px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
  word-wrap: break-word;
}
@media (max-width: 649px) {
  .banner h2 {
    font-size: 45px;
    padding-top: 0px;
  }
}
@media (max-width: 649px) {
  .banner .txt-rotate {
    font-size: 35px;
  }
}
.banner p {
  padding-top: 50px;
  opacity: 0.60;
  font-size: 25px;
  font-weight: 700;
  letter-spacing: 1.5px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}
@media (max-width: 649px) {
  .banner p {
    font-size: 45px;
    padding-top: 20px;
    margin-bottom: -30px;
  }
}
.banner p {
  margin-top: -30px;
  padding-bottom: 50px;
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
@media (max-width: 375px) {
  .banner button {
    margin-top: 0px;  
  }
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}
.banner img {
  /*animation: updown 3s linear infinite; - updown animation*/
  margin-left: -100px;
  width: 120%;
}
@media (max-width: 649px) {
  .banner img {
  margin-top: 30px;
  margin-left: 0px;
  width: 95%;
  }
}
@media (max-width: 375px) {
  .banner img {
  margin-top: 0px;
  }
}
@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}
.banner a {
  text-decoration: none;
}
/************ Welcome Css ************/
.welcome {
  background-image: url("./assets/img/burnslake.webp");
  /* background-image: url("./assets/img/tweedsmir.jpg"); */
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.welcome h1 {
  margin-top: 10vh;
  text-align: center;
  font-size: 85px;
  font-weight: 700;
  letter-spacing: 1.5px;
  line-height: 1;
  display: block;
  color: white;
  text-shadow:
  -1px 0px 0px black,
  1px 0px 0px black,
  0px -1px 0px black,
  0px 1px 0px black;
  word-wrap: break-word;
}
@media (max-width: 649px) {
  .welcome h1 {
    font-size: 55px;
  }
}
@media (max-width: 350px) {
  .welcome h1 {
    font-size: 50px;
  }
}
.welcome .txt-left{
  text-align: left;
  margin-top: 50px;
  margin-bottom: 180px;
}
.welcome .txt-center{
  text-align: center;
}
.welcome .txt-right{
  text-align: right;
  margin-top: 200px;
}
/* Tablet & Mobile title */
@media (max-width: 1050px) {
  .welcome .txt-left,
  .welcome .txt-center,
  .welcome .txt-right{
    margin: 0px;
    padding-top: 60px;
  }
}@media (max-width: 649px) {
  .imagepg h1 {
    font-size: 45px;
  }
}
/************ Contact Css ************/
.contact {
  background: linear-gradient(90.21deg, #7D8F69 -5.91%, #22577E 111.58%);
  padding: 60px 0 200px 0;
  min-height: 66vh;
}
.contact img {
  padding-top: 85px;
  padding-bottom: 65px;
  width: 90%;
  /* animation: updown 3s linear infinite; - updown animation */
}
.contact h2 {
	font-size: 45px;
	font-weight: 700;
  margin-bottom: 30px;
}
.contact form input, .contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #fff;
}
.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}

/************ Footer Css ************/
.footer {
  position: relative;
  z-index: 10;
  padding-top: 25px; /*black line*/
  background-color: #658147;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
}
.bot-logo{
  width: 100px;
  margin-top: -25px;
}
@media (max-width: 450px) {
  .bot-logo {
    width: 50px;
    margin: 10px;
  }
}

/************ Services Css ************/
.service {
  padding-top: 50px;
  background-color: #658147;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 200px;
}
@media (max-width: 1025px){
  .service {
    background-size: auto;
  }
}
.service h2 {
	font-size: 45px;
	font-weight: 700;
  text-align: center;
  margin-top: 100px;
  text-shadow:
  -1px 0px 0px black,
  1px 0px 0px black,
  0px -1px 0px black,
  0px 1px 0px black;
}
.service p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
  text-shadow:
  -1px 0px 0px black,
  1px 0px 0px black,
  0px -1px 0px black,
  0px 1px 0px black;
}
/************ Portfolio Css ************/
/*Portfolio Card CSS*/
.port-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
}
.port-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #7D8F69 -5.91%, #22577E 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.port-imgbx:hover::before {
  height: 100%;
}
.port-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.port-imgbx:hover .port-txtx {
  top: 50%;
  opacity: 1;
}
.port-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.port-txtx a {
  color: white;
  text-decoration: none;
}
.port-txtx:hover a {
  color: white;
  text-decoration: underline;
}
.port-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}

/************ Map Css ************/
.map {
  flex-direction: column;
  background-color: #658147;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}
.mapDiv.fullscreen {
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  background: #658147; /* Ensures clear fullscreen */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Prevents any unwanted scrollbars */
}

.mapDiv.fullscreen img {
  width: auto;
  height: 100vh; /* Ensures it fills the height of the screen */
  object-fit: contain; /* Maintains aspect ratio */
}
.mapContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  height: 100%;
  flex-direction: 'row';
  
}
@media (max-width: 450px) {
    .mapContainer {
    margin-top: 0px;
    flex-direction: 'column';
  }  
}
.mapContainer img {
  height: 100%; /* Ensure image takes up full height initially */
  width: 100%;   /* Ensure image fits within the width */
  object-fit: contain; /* Maintain aspect ratio */
  
}

.map.fullScreen .mapContainer {
  margin-top: 0;
  display: flex;
  justify-content: center;
  align-items: center;

}

.map.fullScreen img {
  width: auto;
  height: 100%; /* Adjust height to fill screen */
  object-fit: contain; /* Ensure aspect ratio is maintained */
}

@media (max-width: 450px) {
  /* Ensure initial mobile layout */
  .map {
    height: 100vh; /* Ensure the map takes up full screen height */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .map.fullScreen {
    transform: rotate(90deg); /* Rotate map when fullscreen */
    width: 100vh; /* Map takes full height after rotation */
    height: 100vw; /* Map takes full width after rotation */
    border-width: 2px;
  }

  .map.fullScreen .mapContainer {
    width: 100%;
    height: 100%;
    align-items: flex-start;
  }

  .map.fullScreen img {
    height: 100%;
    width: auto; /* Maintain aspect ratio */
  }
}

@media (max-width: 1025px) {
  .map {
    background-size: auto;
  }
}
.mapDownload {
  position: absolute;
  right: 0;
  top: 50vh;
  margin-top: 110px;
  margin-right: 10vw;
  
}
@media (max-width: 649px) {
  .mapDownload {
    top: 70vh;
    left: 8vw;
  }  
}
@media (min-width: 650px) and (max-width: 1200px) {
  .mapDownload {
    top: 80vh;
    left: 10vw;
  }
}
@media (max-width: 1920px) {
  .mapDownload {
    margin-right: 4vw;
  }
}    
.mapInstructions {
  position: absolute;
  left: 0;
  top: 50vh;
  margin-left: 7vw;
}
@media (max-width: 450px) {
  .mapInstructions {
    top: 22vh;
    left: 16vw;
  }
}
@media (min-width: 450px) and (max-width: 1200px) {
  .mapInstructions {
    top: 23vh;
    left: 35vw;
  }
}
@media (max-width: 1920px) {
  .mapInstructions {
    margin-left: 1vw;
  }
}    
@media (max-width: 450px) {
  /* Ensure initial mobile layout */
  .mapTitle {
    margin-top: 10vh;
  }
}

.map h1 {
  padding-bottom: 5px;
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 1.5px;
  line-height: 1;
  display: block;
  background: linear-gradient(to right, rgb(255, 255, 255) 0%, rgba(69, 92, 55, 1) 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  
}
@media (min-width:451px) and (max-width: 1050px) {
  .map h1 {
  padding-bottom: 15vh;
  }
}
.map h2 {
  color: white;
	font-size: 30px;
	font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}
.map li {
  font-size: 20px;
}
@media (max-width: 375px) and (max-height: 670px){
  .map h2 {
    font-size: 20px;
  }
  .map li {
    margin-left: 30px;
    font-size: 15px;
  }
}
/************ About Css ************/
.about {
  padding: 150px 0;
  /* background-color: #658147; */
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 649px) {
  .about {
    background-size: auto;
    padding-top: 60px;
    padding-left: 20px;
  }
}
.about h1 {
  margin-top: -30px;
  font-size: 75px;
  font-weight: 700;
  letter-spacing: 1.5px;
  line-height: 1;
  display: block;
  background: linear-gradient(to right, rgb(255, 255, 255) 0%, rgba(69, 92, 55, 1) 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 20px;
}
@media (max-width: 649px) {
  .about h1 {
    font-size: 35px;
    padding-bottom: 0px;
  }
}
.about h2 {
  font-size: 50px;
  font-weight: 500;
  letter-spacing: 0.8px;
  line-height: 1;
  padding-top: 20px;
  margin-bottom: 20px;
  display: block;
  clear: both;
  display: block;
  overflow: hidden;
  white-space: nowrap;
}
@media (max-width: 649px) {
  .about h2 {
    font-size: 30px;
    margin-bottom: 10px;
  }
}
.about h3 {
  padding-top: 20px;
  font-size: 30px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.about a {
  text-decoration: none;
}
.about img {
  padding-top:150px;
}
.about .f-let::first-letter{
  font-size: 60px;
  color: #7D8F69;
  text-shadow:
  -2px 0px 0px #22577E,
  2px 0px 0px #22577E,
  0px -2px 0px #22577E,
  0px 2px 0px #22577E;
  font-family: 'Shojumaru', sans-serif !important;
}
.about p {
  padding-bottom: 20px;
  color: #B8B8B8;
  font-size: 20px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
@media (max-width: 649px) {
  .about p {
    margin-top: 20px;
  }
}
.about button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
@media (max-width: 649px) {
  .about button {
    margin-top: 0px;
  }
}
.about button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.about button:hover svg {
  margin-left: 25px;
}
@media (max-width: 649px){
  .about img {
    padding-top: 0;
    margin-top: 20px;
    margin-bottom: -150px;
    margin-left: -10px;
    width: 100%;
    /* animation: updown 3s linear infinite; - updown animation */
  }
}
/****** ContactMe Page *****/
.contactMe {
  height: 100vh;
  width: 100vw;
}
.contactMe h1 {
  background: linear-gradient(to right, rgb(255, 255, 255) 0%, rgba(69, 92, 55, 1) 35%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.contactMeIn {
  align-items: center;
  justify-content: center;
}
.contactMeIn h1 h2 {
  align-items: center;
  justify-content: center;
}
/****** Sponsors Page *****/
.sponsors {
  margin-top: 50px;
}
.sponsors h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 1.5px;
  line-height: 1;
  display: block;
  background: linear-gradient(to right, rgb(255, 255, 255) 0%, rgba(69, 92, 55, 1) 25%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: left;
}
/****** Image Page *****/
.imagepg {
  padding-top: 85px;
  background-color: #658147;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 150px; 
  /* LOOK HERE */
  flex-direction: column;
}
@media (max-width: 649px) {
  .imagepg {
    padding-bottom: 0px;
  }
}
.imagepg-buttons {
  display: flex;
  justify-content: center;
  align-items: 'center';
  flex-wrap: wrap;
  width: 100%;
}
.imagepg-buttons :hover{
  color: black !important;
  -webkit-text-stroke: 1px white !important;
}
@media (max-width: 649px) {
  .imagepg-buttons {
    margin: 0px;
  }
}
.imagepg h1 {
  z-index: 99999999;
  padding-top: 50px;
  /* LOOK HERE */
  padding-bottom: 20px;
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 1.5px;
  line-height: 1;
  display: block;
  background: linear-gradient(to right, rgb(255, 255, 255) 0%, rgba(69, 92, 55, 1) 62%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}
/* Tablet & Mobile title */
@media (min-width: 650px) and (max-width: 1050px) {
  .imagepg h1 {
    padding-top: 120px;
  }
}@media (max-width: 649px) {
  .imagepg h1 {
    padding-top: 0px;
    font-size: 45px;
  }
}
.imagepg h2 {
	font-size: 30px;
	font-weight: 700;
  text-align: center;
  margin-bottom: 50px;
}

.carousel {
  width: 58vw;
}
@media (max-width: 1050px) {
  .carousel {
    width: 100vw;
  }
}
.carousel-item img {
  height: 74vh;
  object-fit: cover;
}
/*Full screen button*/
.imagepg button {
  font-size: 24px;
  color: white;
  opacity: 100%;
}
/* Position of button to fullscreen */
.fs-button {
  position: absolute;
  top: 24vh;
  right: 21.2vw;
  z-index: 13;
}
@media (min-width: 650px) and (max-width: 1050px) {
  .fs-button {
    top: 260px;
    right: 5px;
  }
}
@media (max-width: 649px) {
  .fs-button {
    top: 170px;
    right: 5px;
  }
}
/* Position of button to minimize */
.sm-button {
  position: fixed;
  top: 0px;
  right: 5px;
  z-index: 13;
}
/* Carousel controls */
.carousel-control-prev,
.carousel-control-next {
  z-index: 12 !important;
  opacity: 100% !important;
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}
/*Fullscreen img*/
.carousel.fullscreen {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 11;
}

.fullscreen .carousel-item img{
  height: 100vh;
  object-fit: contain;
}

.imagepg.fullscreen {
  background-image: none;
  background-color: #658147;
  z-index: 11;
}
/****** Camping *****/
@media (max-width: 649px) {
  .imagepg.camping h1 {
    padding-top: 0px;
  }
  .imagepg.camping h2 {
    font-size: 20px;
    margin-bottom: 20px;
  }
}
/****** Campsite Status *****/
.carouselContainer {
  display: flex;
  flex-direction: row;
}
@media (max-width: 649px) {
  .carouselContainer {
    flex-direction: column;
  }
}
@media (max-width: 649px) {
  .siteInfo {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background-color: #658147;
  }
}
.campsiteInfo, .campsiteStatus {
  position: absolute; 
  top: 220px; 
  width: 15vw; 
  height: 74vh;
}
.campsiteInfo {
  left: 0;
  margin-left: 2.8vw;
}
@media (max-width: 649px) {
  .campsiteInfo, .campsiteStatus {
    position: relative; 
    width: 100vw;
    margin-left: 0;
    margin-right: 0;
    height: auto;
  }
  .campsiteInfo {
    margin-bottom: 20px; /* Adjust as needed */
  }
}
.campsiteStatus {
  right: 0; 
  margin-right: 2.8vw; 
}
.campsiteStatus a {
  text-decoration-color: white;
  color: white; 
}
.campsiteStatus a:hover {
  font-weight: 700;
}
.campsiteInfo h2, .campsiteStatus h2 {
  margin-top: 190px; 
  margin-bottom: 8px; 
  font-size: 50px;
}
.campsiteInfo ul, .campsiteStatus ul {
  margin-left: 30px; 
  font-size: 25px;
}
@media (max-width: 649px) {
  .campsiteInfo h2 {
    margin-top: -50px; 
    font-size: 40px;
  }
  .campsiteStatus h2 {
    margin-top: 50px; 
    font-size: 40px;
  }
  .campsiteInfo ul, .campsiteStatus ul {
    margin-left: 25vw; 
    font-size: 30px;
  }
}
/* Donations Page */
.donations {
  padding-top: 150px;
  padding-left: 15px;
  background-color: #658147;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 767px) {
  .donations {
    background-size: auto;
  }
}
.donations h1 {
  margin-top: -30px;
  font-size: 75px;
  font-weight: 700;
  letter-spacing: 1.5px;
  line-height: 1;
  display: block;
  background: linear-gradient(to right, rgb(255, 255, 255) 0%, rgba(69, 92, 55, 1) 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 20px;
}
.donations h1 {
  padding-top: 50px;
}
@media (max-width: 649px) {
  .donations h1 {
    margin-top: -80px;
    padding-top: 0px;
    font-size: 65px;
  }
}
.donations p {
  padding-bottom: 20px;
  color: #B8B8B8;
  font-size: 20px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
  text-shadow:
  -1px 0px 0px black,
  1px 0px 0px black,
  0px -1px 0px black,
  0px 1px 0px black;
}
@media (max-width: 649px) {
  .donations p {
    padding-bottom: 0px;
  }
}
.donations button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 25px 75px;
  font-size: 25px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
  margin-bottom: 50px;
}
@media (max-width: 649px) {
  .donations button {
    margin-bottom: 20px;
  }
}
.donations button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.donations button:hover {
  color: #121212;
}
.donations button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}
/****Sponsors*****/
.sponsors h1 {
  margin-top: 150px;
}
@media (max-width: 649px) {
  .sponsors h1 {
    margin-top: 0px;
  }
}
@media (max-width: 649px) {
  .sponsors button {
    margin-top: 50px;
    margin-bottom: 20px;
  }
}
/***** VideoPage *****/
.videoPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.videoDiv {
  width: 70vw;
  height: calc(70vw * 9 / 16); /* Correct 16:9 aspect ratio */
}
@media (max-width: 1050px) {
  .videoDiv {
    margin-top: 0px;
    width: 100vw;
    height: calc(100vw * 9 / 16); /* Correct 16:9 aspect ratio */
  }
}
.videoPage h1 {
  font-size: 90px;
  font-weight: 700;
  letter-spacing: 1.5px;
  line-height: 1;
  display: block;
  background: linear-gradient(to right, rgb(255, 255, 255) 0%, rgba(69, 92, 55, 1) 75%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  word-wrap: break-word;
  margin-top: 50px;
  margin-bottom: 20px;
}
@media (max-width: 1050px) {
  .videoPage h1 {
    margin-top: -100px;
    margin-bottom: 100px;
  }
}